<template>
  <v-textarea
    v-if="textarea != undefined"
    rows="3"
    :id="id"
    v-model.trim="val"
    :error="!!error"
    :error-messages="error"
    :label="mylabel"
    outlined
    filled
  ></v-textarea>
  <!--
    <b-form-textarea :id="id" v-model="val" placeholder=" "></b-form-textarea>
    <label :for="id">{{ mylabel }}</label>
    <small v-if="error" class="form-text text-danger">{{ error }}</small>
  -->

  <v-text-field
    v-else
    :autocomplete="autocompleteValue"
    :id="id"
    v-model.trim="val"
    :label="mylabel"
    :error="!!error"
    :error-messages="error"
    outlined
    filled
  ></v-text-field>
  <!--
    <b-form-input :id="id" v-model.trim="val" :placeholder="mylabel"></b-form-input>
    <label :for="id">{{ mylabel }}</label>
    <small v-if="error" class="form-text text-danger">{{ error }}</small>
--></template>

<script>
export default {
  name: "ContactInput",

  props: ["name", "group", "textarea", "label"],

  data() {
    return {
      val: null,
    };
  },

  mounted() {
    if (this.group == "contact") {
      this.val = this.$root.contactForm.contact[this.name];
    }
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  computed: {
    invalid() {
      return this.$root.contactForm.errors.indexOf(this.name) > -1;
    },

    autocompleteValue() {
      const mapping = [
        { name: "firstname", autocomplete: "given-name" },
        { name: "lastname", autocomplete: "family-name" },
        { name: "email", autocomplete: "email" },
        { name: "phone", autocomplete: "tel" },
        { name: "street", autocomplete: "street-address" },
        { name: "zip", autocomplete: "postal-code" },
        { name: "city", autocomplete: "locality" },
        { name: "salutation", autocomplete: "honorific-prefix" },
      ];

      const map = mapping.find((m) => m.name == this.name);
      if (map) {
        return map.autocomplete;
      }

      return undefined;
    },

    required() {
      return this.$root.contactForm.isRequired(this.name);
    },

    error() {
      if (this.invalid) {
        return "Pflichtfeld";
      }

      return null;
    },

    mylabel() {
      if (this.required) {
        return `${this.label}*`;
      }

      return this.label;
    },

    id() {
      return `contact__${this.name}`;
    },
  },

  methods: {
    mapValue() {
      if (this.group == "contact") {
        this.$root.contactForm.contact[this.name] = this.val;
      }
    },
  },
};
</script>
