<template>
<v-tabs
  v-model="tab"
  @change="update"
  class="elevation-0 immo-expose__tabs">
  <v-tabs-slider></v-tabs-slider>

  <v-tab
    v-for="i in tabs"
    :key="i.name"
    :href="`#t${i.name}`">
      {{ i.title }}
  </v-tab>

  <v-tab-item
    v-for="i in tabs"
    :key="i.name"
    :value="'t' + i.name">

    <v-card :outlined="true">
      <v-card-text class="immo-expose__excerpt" v-html="i.value">
      </v-card-text>
    </v-card>

  </v-tab-item>
</v-tabs>
</template>

<script>
export default {
  name: "ExposeTab",

  data() {
    return {
      tab: null
    }
  },
  watch: {
    tab: function () {
      window.setTimeout(() => {
        this.$yeti.init_readmore();
      }, 300);
    }
  },
  computed: {
    tabs() {
      return this.$root.tabs;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$yeti.init_readmore();
    });
  },

  methods: {
    update() {
      this.$nextTick(() => {
        this.$yeti.init_readmore();
      });
    }
  }
}
</script>
