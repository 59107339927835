<template>
<v-app>
<v-dialog
    v-model="dialog"
    width="688">
    <v-card class="team__interview-modal">
      <v-card-title
        class="w-100"
        primary-title
      >
        <img :src="image.url" :alt="image.alt" />
          <div class="bg-secondary team__interview-title">
            {{ title }}
          </div>

          <div
            class="team__interview-close-times"><v-btn
            icon
            dark
            @click="dialog = false">
            <span class="fal fa-2x fa-times text-secondary"></span>
          </v-btn>
          </div>
      </v-card-title>

      <v-card-text>
        <div v-html="text"></div>
      </v-card-text>

      <v-card-actions>
        <button
          class="btn btn-primary"
          @click="dialog = false"
        >
          Zur Team-Übersicht
        </button>
      </v-card-actions>
    </v-card>
</v-dialog>
</v-app>
</template>

<script>
export default {
  name: "Modal",

  data() {
    return {
      dialog: false
    }
  },

  computed: {
    name() {
      return this.$root.name;
    },

    title() {
      return this.$root.title;
    },

    content() {
      return this.$root.content;
    },

    text() {
      return this.content.content;
    },

    image() {
      return this.content.image;
    }
  },

  mounted() {
    const openButton = document.querySelector("a[href='#" + this.name + "']");
    if (openButton) {
      openButton.addEventListener("click", (e) => {
        e.preventDefault();

        this.dialog = true;
      });
    }
  }
}
</script>
