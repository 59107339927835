import Vue from "vue";
import depsloader from "../depsloader";
import Listing from "../components/immo/Listing.vue";
import VueAxios from 'vue-axios'

export default () => {
  const element = document.querySelector("#vue-immo-listing");
  if (element) {
    depsloader.load("axios").then((axios) => {
      Vue.use(VueAxios, axios);

      var status = null;
      if (element.dataset.status) {
        status = element.dataset.status;
      }

      var maxPosts = null;
      if (element.dataset.maxPosts) {
        maxPosts = element.dataset.maxPosts;
      }

      new Vue({
        el: element,
        components: {
          Listing
        },

        computed: {
          requestUrl() {
            if (element.dataset.requestUrl) {
              return element.dataset.requestUrl;
            }

            return null;
          }
        },

        render: h => h(Listing, { props: { status: status, maxPosts: maxPosts } })
      });
    });
  }
};
