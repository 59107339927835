<template>
  <v-app>
    <contact-form
      :name="name"
      :required-fields="requiredFields">
        <contact-fields :exclude="exclude"></contact-fields>
    </contact-form>
  </v-app>
  </template>

<script>
  import ContactForm from "./ContactForm";
  import ContactFields from "./ContactFieldsProjectListing.vue";

  export default {
    name: "ProjectSelectionApp",

    components: {
      ContactForm,
      ContactFields
    },

    data() {
      return {
        name: "project_selection_mim"
      }
    },

    computed: {
      requiredFields() {
        return this.$yeti.formRequiredFields(this.name);
      },

      exclude() {
        let el = [];
        if (this.$root.exclude) {
          el = this.root.exclude;
        }

        return el;
      }
    }
  };
</script>
