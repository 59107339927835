<template>
<v-app>
  <contact-form
    class="kundenbewertung-form"
    :name="name"
    :required-fields="requiredFields"
    button-text="Bewertung abgeben">
      <contact-fields-events-befragung></contact-fields-events-befragung>
  </contact-form>
</v-app>
</template>

<script>
import ContactForm from "./ContactForm";
import ContactFieldsEventsBefragung from './ContactFieldsEventsBefragung.vue';

export default {
  name: "EventsBefragung",

  components: {
    ContactForm,
    ContactFieldsEventsBefragung
  },

  data() {
    return {
      name: "eventsbefragung"
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    }
  }
};
</script>
