import ImmoappLoader from "../misc/ImmoappLoader";
import Table from "../components/table/Table.vue";

export default function() {
  const element = document.querySelector("#vue-table");
  if (!element) {
    return;
  }

  ImmoappLoader(element, Table, {
    props: {
      labels: JSON.parse(element.dataset.labels),
      units: JSON.parse(element.dataset.units)
    }
  });
}
