<template>
<div class="immo-archive">
  <div class="mb-3 text-center">
    <a v-for="category in categories"
        :key="category.term.term_id"
        @click.prevent="toggleTerm(category.term.term_id)"
        class="btn-reference"
        v-bind:class="{ 'active' : ignoredTerms.indexOf(category.term.term_id) != -1 }">
      {{ category.term.name }} ({{ category.count }}) <i class="fal fa-times"></i>
    </a>
  </div>

<div class="position-relative">
  <div class="immo__listing__anker" ref="listingAnker"></div>
  <span class="gray gray--small"></span>


<div class="container d-block">
  <div class="wp-block-columns is-style-8-8-8">
    <div v-for="property in properties"
        :key="property.objectId"
        v-html="property.html"
        class="wp-block-column mb-3">
    </div>
  </div>

  <div v-if="!maxPosts && pages && pages > 1" class="pagination text-center mb-4 mt-2">
    <a class="prev page-numbers"
      @click.prevent="prev"><span class="fal fa-chevron-left"></span></a>

    <a class="page-numbers"
      v-for="p in pages"
      :key="p"
      @click.prevent="open(p);"
      v-bind:class="{ current: p == activePage }">
      {{ p }}
    </a>

    <a class="next page-numbers"
      @click.prevent="next"><span class="fal fa-chevron-right"></span></a>
  </div>
</div>
</div>
</div>
</template>

<script>
import ImmoPreview from "../../entrypoints/immo-preview.js";

export default {
  name: "Listing",

  components: {},

  data() {
    return {
      properties: [],
      categories: [],
      page: 1,
      pages: null,
      activePage: null,
      firstRun: true,
      ignoredTerms: [],
      backend:""
    }
  },

  props: [
    "status",
    "maxPosts"
  ],

  computed: {
    scrollTop() {
      var yPosition = 0;
      var element = this.$refs.listingAnker;
      while(element) {
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
      }

      return yPosition;
    }
  },

  methods: {
    load() {
      if (this.page == this.activePage) {
        return;
      }

      let requestUrl = "/wp-json/rest/immo/v1/listing";
      if (this.$root.requestUrl) {
        requestUrl = this.$root.requestUrl;
      }

      let params = this.getParams();
      this.$http.get(this.backend + requestUrl, {
          params: params
      }).then((response) => {
        this.page = response.data.page;
        this.activePage = response.data.page;
        this.pages = response.data.pages
        this.properties = response.data.entries;
        this.$nextTick(() => {
          if (!this.firstRun) {
            this.scrollToTop();
            var searchParameters = new URLSearchParams();
            Object.keys(params).forEach((parameterName) => {
              if (this.$options._propKeys.indexOf(parameterName) == -1) {
                searchParameters.append(parameterName, params[parameterName]);
              }
            });

            window.location.hash = searchParameters;
          }

          this.firstRun = false;

          ImmoPreview();
        });
      });
    },

    getParams() {
      return {
        page: this.page,
        ignoredTerms:this.ignoredTerms,
        status:this.status,
        maxPosts:this.maxPosts
      }
    },

    scrollToTop() {
      window.scrollTo({
        top: this.scrollTop,
        left: 0,
        behavior: 'smooth'
      });
    },

    open(p) {
      this.page = p;
      this.load();
    },

    prev() {
      this.page--;
      if (this.page < 1) {
        this.page = 1;
      }

      this.load();
    },

    next() {
      this.page++;
      if (this.pages && this.page > this.pages) {
        this.page = this.pages;
      }

      this.load();
    },

    toggleTerm(termId) {
      if (this.ignoredTerms.indexOf(termId) != -1) {
        this.ignoredTerms = this.ignoredTerms.filter(id => id != termId);
      } else {
        this.ignoredTerms.push(termId);
      }

      this.activePage = null;
      this.page = 1;
      this.load();
    }
  },

  mounted() {
    const htmlLang = document.querySelector("html").getAttribute("lang");
    if (htmlLang == "en-US") {
      this.backend = "/en";
    }

    (new URLSearchParams(window.location.hash.replace("#", ""))).forEach((value, key) => {
      if (typeof this[key] !== "undefined" && value != "") {
        if (typeof this[key] == "object") {
          this[key] = value.split(",").map(id => parseInt(id)).filter((v, i, a) => a.indexOf(v) === i);
        } else if (typeof this[key] == "number") {
          this[key] = parseInt(value);
        }
      }
    });

    // this.$http.get(this.backend + "/wp-json/rest/immo/v1/categories", {
    //   params: this.getParams()
    // }).then((response) => {
    //   this.categories = response.data;
    // });

    this.load();
  }
}
</script>
