<template>
<app-wrapper :standalone="true" :noLoading="true">
<v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title
        class="headline bg-black w-100"
        primary-title
      >
          <span v-html="headerTitle"></span>
          <v-spacer></v-spacer>
          <div
            class="float-right"><v-btn
            icon
            dark
            @click="dialog = false">
            <span class="fal fa-2x fa-times"></span>
          </v-btn>
          </div>
      </v-card-title>

      <v-card-text class="position-relative">
        <p class="h4 mt-1" v-html="title"></p>

        <contact-form :requiredFields='requiredFields'
          submitUrl="/wp-json/sw-immoapp/v1/contact/expose-contact"
          :authentication-token="authenticationToken"
          :prop-immo-expose="[exposeId, customObjectId]"
          :buttonText='$t("Newsletter abonnieren")'
          buttonWrapperClass="text-center"

          name="exposePreviewContact"
          buttonClass="btn btn-primary btn-sm btn-snomw"
        >
          <contact-logged-in></contact-logged-in>
          <contact-fields v-if="loggedIn" :exclude="['company', 'person', 'phone_email']"></contact-fields>
          <contact-fields v-else :exclude="['company', 'message']"></contact-fields>
        </contact-form>
      </v-card-text>
    </v-card>
</v-dialog>
</app-wrapper>
</template>

<script>
import AppWrapper from "../immoapp/AppWrapper";
import ContactLoggedIn from "../immoapp/contact/ContactLoggedIn";
import ContactForm from "../contact/ContactForm";
import ContactFields from "../contact/ContactFields";

export default {
  name: "Modal",

  components: {
    AppWrapper,
    ContactForm,
    ContactFields,
    ContactLoggedIn
  },

  props: [
    "exposeId",
    "title",
    "customObjectId"
  ],

  data() {
    return {
      dialog: false
    }
  },

  computed: {
    headerTitle() {
      return this.customObjectId;
    },

    requiredFields() {
      return this.$yeti.formRequiredFields("exposePreviewContact");
    },

    loggedIn() {
      return this.$store.state.user.loggedIn;
    },

    username() {
      return this.$store.state.user.email;
    },

    appLoaded() {
      return this.$store.state.appLoaded;
    },

    authenticationToken() {
      return this.$store.state.auth.authenticationToken;
    }
  },

  mounted() {
    const openButton = document.querySelector("a[href='#contact" + this.exposeId + "']");
    if (openButton) {
      openButton.addEventListener("click", (e) => {
        e.preventDefault();

        this.dialog = true;
      });
    }
  }
}
</script>
