import depsloader from "../depsloader";

export default function() {
  const element = document.querySelector(".iframe-resizer");

  function loadExternalContent() {
    depsloader.load("iframe-resizer").then((iframeResizer) => {
      const tpl = element.querySelector("iframe");

      iframeResizer.iframeResize({
        log: false,
        heightCalculationMethod: 'lowestElement',
        widthCalculationMethod: 'rightMostElement'
      }, tpl);
    });
  }

  if (element) {
    let observer = new IntersectionObserver(() => {
      const CookieConsentAcceptBtn = element.querySelector("sw-privacy-media button.wp-block-button__link");

      if (CookieConsentAcceptBtn && !CookieConsentAcceptBtn.hasAttribute("listener")) {
        CookieConsentAcceptBtn.addEventListener("click", () => {
          window.setTimeout(() => {
            loadExternalContent();
          }, 300);
        });
        CookieConsentAcceptBtn.setAttribute("listener", true)
      } else {
        loadExternalContent();
      }
    }, {
      rootMargin: '0px',
      threshold: 1.0
    });

    observer.observe(element);
  }
}
