import ImmoappLoader from "../misc/ImmoappLoader";
import SearchApp from "../components/immoapp/SearchApp";

export default function() {
  const element = document.querySelector("#vue-immoapp-search");
  if (!element) {
    return;
  }

  ImmoappLoader(element, SearchApp, {
    props: {
      data: JSON.parse(element.dataset.data),
      params: JSON.parse(element.dataset.params),
      mode: element.dataset.mode,
      action: element.dataset.action
    }
  });
}
