import Vue from "vue";
import depsloader from "../depsloader";
import Archive from "../components/testimonials/Archive.vue";
import VueAxios from 'vue-axios';
import vuetify from "../vuetify.js";

export default () => {
  const element = document.querySelector("#vue-testimonial-archive");
  if (element) {
    depsloader.load("axios").then((axios) => {
      Vue.use(VueAxios, axios);

      var ignorePosts = [];
      if (element.dataset.ignorePosts) {
        ignorePosts = JSON.parse(element.dataset.ignorePosts);
      }

      let vueArgs = {};
      vueArgs = {...vueArgs, ...{
        el: element,
        components: {
          Archive
        },
        vuetify,

        render: h => h(Archive, { props: { ignorePosts: ignorePosts } })
      }};

      new Vue(vueArgs)
    });
  }
};
