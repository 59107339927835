<template>
<v-app>
  <contact-form
    class="kundenbewertung-form"
    :name="name"
    :required-fields="requiredFields"
    button-text="Bewertung abgeben">
      <contact-fields-kundenbewertung></contact-fields-kundenbewertung>
  </contact-form>
</v-app>
</template>

<script>
import ContactForm from "./ContactForm";
import ContactFieldsKundenbewertung from "./ContactFieldsKundenbewertung";

export default {
  name: "Kundenbewertung",

  components: {
    ContactForm,
    ContactFieldsKundenbewertung
  },

  data() {
    return {
      name: "kundenbewertung"
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    }
  }
};
</script>
