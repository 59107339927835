<template>
<div class="suchprofil-form">
  <h2>Suchprofil</h2>

  <div>
    <h3>1. Immobilienart: <small>Nach welcher Immobilienart suchen Sie?*</small></h3>
    <contact-group-checkbox wrapperClassNames="grid-4-cols" name="immobilienart" group="contact" :values="['Häuser', 'Wohnungen']"></contact-group-checkbox>
  </div>

  <div>
    <h3>2. Objektart: <small>Welche Objektart wird gesucht?*</small></h3>
    <contact-group-checkbox wrapperClassNames="grid-4-cols" name="objektart" group="contact" :values="['Reihenhaus', 'Stadthaus', 'Doppelhaushälfte', 'Einfamilienhaus', 'Erdgeschosswohnung', 'Etagenwohnung', 'Penthouse']"></contact-group-checkbox>
  </div>

  <div>
    <h3>3. Gebiet: <small>In welchem Gebiet soll die Immobilie liegen?*</small></h3>
    <contact-group-checkbox wrapperClassNames="grid-4-cols" name="gebiet" group="contact" :values="['Nord', 'Ost', 'Süd', 'West', 'München Land', 'LK Dachau', 'LK Ebersberg', 'LK Freising', 'LK Erding', 'LK FFB', 'LK Starnberg', 'LK WOR']"></contact-group-checkbox>
  </div>

  <div>
    <h3>4. Kaufpreis: <small>Welches Budget haben Sie?*</small></h3>
    <div v-if="includeSpecialFields && includeSpecialFields.indexOf('mim2023') !== false">
      <contact-group-checkbox wrapperClassNames="grid-4-cols" name="kaufpreis" group="contact" :values="['bis 750.000 €', 'bis 900.000 €', 'bis 1.000.000 €', 'bis 1.500.000 €', 'bis 1.750.000 €', 'bis 2.000.000 €', 'bis 2.500.000 €', 'über 2.500.000 €']"></contact-group-checkbox>
    </div>
    <div v-else>
      <contact-group-checkbox wrapperClassNames="grid-4-cols" name="kaufpreis" group="contact" :values="['bis 1.000.000 €', 'bis 1.500.000 €', 'bis 1.750.000 €', 'bis 2.000.000 €', 'bis 2.500.000 €', 'über 2.500.000 €']"></contact-group-checkbox>
    </div>
  </div>

  <div>
    <h3>5. Wohnfläche: <small>Wie groß soll die Immobilie sein?*</small></h3>
    <div class="row row-small">
      <div class="col-md-12">
        <contact-input name="wohnflaeche_von" :label="$t('Wohnfläche (m²) von')" group="contact"></contact-input>
      </div>

      <div class="col-md-12">
        <contact-input name="wohnflaeche_bis" :label="$t('Wohnfläche (m²) bis')" group="contact"></contact-input>
      </div>
    </div>
  </div>

  <div>
    <h3>6. Grundstücksfläche: <small>Wie groß soll das Grundstück sein?</small></h3>
    <div class="row row-small">
      <div class="col-md-12">
        <contact-input name="grundstueck_von" :label="$t('Grundstücksfläche (m²) von')" group="contact"></contact-input>
      </div>

      <div class="col-md-12">
        <contact-input name="grundstueck_bis" :label="$t('Grundstücksfläche (m²) bis')" group="contact"></contact-input>
      </div>
    </div>
  </div>

  <div>
    <h3>7. Zimmer Anzahl: <small>Wie viele Zimmer werden min. benötigt?*</small></h3>
    <contact-group-checkbox wrapperClassNames="grid-4-cols" name="zimmer_anzahl" group="contact" :values="['2', '3', '4', '>4']"></contact-group-checkbox>
  </div>

  <div>
    <h3>8. Stellplatz: <small>Wie viele Stellplätze werden benötigt?</small></h3>
    <contact-group-checkbox wrapperClassNames="grid-4-cols" name="stellplatz" group="contact" :values="['1', '2', '3', '>3']"></contact-group-checkbox>
  </div>

  <div>
    <h3>9. Infrastruktur: <small>Was ist Ihnen wichtig?</small></h3>
    <contact-group-checkbox wrapperClassNames="grid-3-cols" name="infrastruktur" group="contact" :values="['Einkaufsmöglichkeiten', 'Kindergarten', 'Schule', 'Autobahnanschluss', 'Bahnhof', 'öfftl. Verkehrsmittel']"></contact-group-checkbox>
  </div>

  <div v-if="includeSpecialFields && includeSpecialFields.indexOf('mim2023') !== false">
    <div>
      <h3>10. Bauweise: <small>Haben Sie Interesse daran Ihr Haus in Holzbauweise errichten zu lassen? (Die Mehrkosten liegen bei ca. 10 Prozent)</small></h3>
      <contact-group-checkbox wrapperClassNames="grid-3-cols" name="bauweise" group="contact" :values="[ 'Ja', 'Nein' ]"></contact-group-checkbox>
    </div>

    <div>
      <h3>11. Nachhaltigkeit: <small>Sind Sie interessiert an einem nachhaltig-zertifiziertem Haus? (Die Mehrkosten liegen bei ca. 10 Prozent)</small></h3>
      <contact-group-checkbox wrapperClassNames="grid-3-cols" name="nachhaltigkeit" group="contact" :values="[ 'Ja', 'Nein' ]"></contact-group-checkbox>
    </div>
  </div>





  <div class="mt-3">
    <h2>Kontakt</h2>
  </div>

  <div class="row row-small" v-if="exclude.indexOf('person') == -1">
    <div class="col-md-12">
      <contact-select name="salutation" :label="$t('Anrede')" group="contact" :values="$t('Frau,Herr')" :placeholder="$t('Anrede')"></contact-select>
    </div>
    <div class="col-md-12">
      <contact-input name="title" :label="$t('Titel')" group="contact"></contact-input>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-md-12">
      <contact-input name="firstname" :label="$t('Vorname')" group="contact"></contact-input>
    </div>
    <div class="col-md-12">
      <contact-input name="lastname" :label="$t('Nachname')" group="contact"></contact-input>
    </div>
  </div>

  <div class="row row-small" v-if="exclude.indexOf('phone_email') == -1">
    <div class="col-12">
      <contact-input name="phone" :label="$t('Telefon')" group="contact"></contact-input>
    </div>
    <div class="col-12" v-if="exclude.indexOf('email') == -1">
      <contact-input name="email" :label="$t('E-Mail')" group="contact"></contact-input>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-24 col-md-10">
      <contact-input name="street" :label="$t('Straße')" group="contact"></contact-input>
    </div>
    <div class="col-12 col-md-7">
      <contact-input name="zip" :label="$t('PLZ')" group="contact"></contact-input>
    </div>
    <div class="col-12 col-md-7">
      <contact-input name="city" :label="$t('Ort')" group="contact"></contact-input>
    </div>
  </div>

  <div class="row" v-if="exclude.indexOf('message') == -1 && ( includeSpecialFields && includeSpecialFields.indexOf('mim2023') === false )">
    <div class="col-sm-24">
      <contact-input textarea :label="$t('Nachricht')" name="message" group="contact"></contact-input>
    </div>
  </div>

  <div class="row" v-if="exclude.indexOf('contactType') == -1">
    <div class="col-sm-24">
      <v-radio-group v-model="contactType" row :label="$t('Kontakt per:')" class="py-0 my-0" :hide-details="true">
        <v-radio hide-details :label="$t('E-Mail')" value="E-Mail" off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
        <v-radio hide-details :label="$t('Telefon')" value="Telefon" off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
      </v-radio-group>
    </div>
  </div>
</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactSelect from "./ContactSelect.vue";
import ContactGroupCheckbox from "./ContactGroupCheckbox.vue";

export default {
  name: "ContactFieldsSuchprofil",

  components: {
    ContactInput,
    ContactSelect,
    ContactGroupCheckbox
  },

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      contactType: []
    };
  },

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    },

    includeSpecialFields() {
      if ( this.$root.includeFields ) {
        return this.$root.includeFields;
      }

      return null;
    }
  },

  mounted() {
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      this.$root.contactForm.contact.contactType = this.contactType;
    }
  }
};
</script>
