<template>
<div>
  <h3 v-if="config.title">
    <contact-input-hidden name="eventName" :value="config.title" group="contact"></contact-input-hidden>
  </h3>

  <div class="row row-small" v-if="config.customObjectId">
    <contact-input-hidden name="customObjectId" :value="config.customObjectId" group="contact"></contact-input-hidden>
  </div>

  <!--
  <div class="row row-small" v-if="getEventDates">
    <div class="col-md-24">
      <contact-select name="eventDate" :label="$t('Datum')" group="contact" :values="getEventDates" :placeholder="$t('Datum')"></contact-select>
    </div>
  </div>
  -->

  <div class="row row-small">
    <div class="col-md-6">
      <contact-select name="salutation" :label="$t('Anrede')" group="contact" :values="$t('Frau,Herr,Divers')" :placeholder="$t('Anrede')"></contact-select>
    </div>

    <div class="col-md-9">
      <contact-input name="firstname" :label="$t('Vorname')" group="contact"></contact-input>
    </div>

    <div class="col-md-9">
      <contact-input name="lastname" :label="$t('Nachname')" group="contact"></contact-input>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-md-24">
      <contact-select name="particpantAmount" :label="$t('Anzahl Teilnehmer')" group="contact" :values="participantAmount" :placeholder="$t('Anzahl Teilnehmer')"></contact-select>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-md-24">
      <v-checkbox hide-details v-model="eventNotParticipantChecked" off-icon="fas fa-square" on-icon="fas fa-check-square">
        <template v-slot:label>
          <span class="form--large-checkbox">
            Ich komme NICHT
          </span>
        </template>
      </v-checkbox>
    </div>
  </div>

  <!--
  <div class="row row-small">
    <div class="col-md-12">
      <contact-input name="phone" :label="$t('Telefon')" group="contact"></contact-input>
    </div>

    <div class="col-md-12">
      <contact-input name="email" :label="$t('E-Mail')" group="contact"></contact-input>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-24 col-md-10">
      <contact-input name="street" :label="$t('Straße')" group="contact"></contact-input>
    </div>
    <div class="col-12 col-md-7">
      <contact-input name="zip" :label="$t('PLZ')" group="contact"></contact-input>
    </div>
    <div class="col-12 col-md-7">
      <contact-input name="city" :label="$t('Ort')" group="contact"></contact-input>
    </div>
  </div>
  -->

</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactInputHidden from "./ContactInputHidden.vue";
import ContactSelect from "./ContactSelect.vue";

export default {
  name: "ContactFieldsEvents",

  components: {
    ContactInput,
    ContactInputHidden,
    ContactSelect
  },

  data() {
    return {
      eventNotParticipantChecked: false
    }
  },

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    },

    config() {
      return this.$root.config;
    },

    participantAmount() {
      let amount = "";
      for (let i = 1; i <= this.config.eventParticipantAmount; i++) {
        if (amount != "") {
          amount += ",";
        }

        amount += i;
      }

      return amount;
    },

    getEventDates() {
      if (this.config.eventDates.length > 0) {
        return this.config.eventDates;
      }

      return false;
    },
  },

  mounted() {
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      this.$root.contactForm.contact.eventNotParticipantChecked = this.eventNotParticipantChecked;
    }
  }
};
</script>
