<template>
<div>

  <div>
    <p><span class="h4">1. Die Organisation:</span></p>
    <contact-fields-kundenbewertung-radios group="contact" name="organisation"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">2. Den Wochentag:</span></p>
    <contact-fields-kundenbewertung-radios group="contact" name="wochentag"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">3. Die Uhrzeit:</span></p>
    <contact-fields-kundenbewertung-radios group="contact" name="uhrzeit"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">4. Den Veranstaltungsort:</span></p>
    <contact-fields-kundenbewertung-radios group="contact" name="veranstaltungsort"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">5. Die Auswahl der Speisen und Getränke:</span></p>
    <contact-fields-kundenbewertung-radios group="contact" name="auswahlSpeisenGetraenke"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">6. Die Qualität der Speisen und Getränke:</span></p>
    <contact-fields-kundenbewertung-radios group="contact" name="qualitaetSepisenGetraenke"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">7. Die Musik:</span></p>
    <contact-fields-kundenbewertung-radios group="contact" name="musik"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">8. Die Atmosphäre:</span></p>
    <contact-fields-kundenbewertung-radios group="contact" name="atmosphaere"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <contact-input textarea :label="$t('Vorschläge, wie wir die Veranstaltung noch besser machen können: (optional)')" name="vorschlaege" group="contact"></contact-input>
  </div>

</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactFieldsKundenbewertungRadios from "./ContactFieldsKundenbewertungRadios.vue";

export default {
  name: "ContactFieldsEventBefragung",

  components: {
    ContactInput,
    ContactFieldsKundenbewertungRadios
  },

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    }
  },

  mounted() {
    this.$root.contactForm.registerField(this);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("kdnr")) {
      this.$root.contactForm.contact.kdnr = urlParams.get("kdnr");
    }

    if (urlParams.get("email")) {
      this.$root.contactForm.contact.email = urlParams.get("email");
    }
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
    }
  }
};
</script>
