<template>
<v-app>
  <div
    ref="dataTable" class=" ">
  <v-data-table
    :headers="labels"
    :items="units"
    class="elevation-0"
    hide-default-footer
    disable-pagination
    :mobile-breakpoint="900"
  >
    <template v-slot:[`item.plot`]="{ item }">
      <a @click.prevent="openGallery(item.plot, item.title)">
        <i class="fal fa-search"></i>
      </a>
    </template>

    <template v-slot:[`item.link`]="{ item }">
      <span v-if="item.statusHomepage == 'nicht verfügbar'">
        {{ item.price }}
      </span>
      <span v-else>
        <a :href="item.link" v-if="item.link">
          {{ $t("Zum Online-Exposé") }}
        </a>
      </span>
    </template>

    <template v-slot:[`item.livingSpace`]="{ item }">
      <span v-if="item.livingSpace">
      ca. {{ $n(item.livingSpace, "decimal") }} m²
      </span>
      <span v-else>
        {{ $t("Keine Angabe") }}
      </span>
    </template>

    <template v-slot:[`item.totalSpace`]="{ item }">
      <span v-if="item.totalSpace">
      ca. {{ $n(item.totalSpace, "decimal") }} m²
      </span>
      <span v-else>
        {{ $t("Keine Angabe") }}
      </span>
    </template>

	<template v-slot:[`item.gardenArea`]="{ item }">
      <span v-if="item.gardenArea">
      ca. {{ $n(item.gardenArea, "decimal") }} m²
      </span>
    </template>


    <template v-slot:[`item.customObjectId`]="{ item }">
      <span :id="item.customObjectId">{{ item.customObjectId }}</span>
    </template>

    <template v-slot:[`item.price`]="{ item }">
      <span v-if="'nicht verfügbar' == item.statusHomepage">
      </span>
      <span v-else v-html="isNaN(item.price) ? item.price : $n(item.price, 'currency')"></span>
    </template>

    <template v-slot:[`item.additional_costs`]="{ item }">
      <span v-html="item.additional_costs ? $n(item.additional_costs, 'currency') : '-'"></span>
    </template>

    <template v-slot:[`item.car`]="{ item }">
      <span v-if="item.carprice" v-html="item.carprice ? $n(item.carprice, 'currency') : '-'">
      </span>

      <span v-if="item.car">
        ({{ item.car }}&nbsp;verfügbar)
      </span>
      <span v-else>-</span>

    </template>

    <template v-slot:[`item.warmrent`]="{ item }">
      {{ item.warmrent ? $n(item.warmrent, "currency") : "-" }}
    </template>
  </v-data-table>
  </div>
</v-app>
</template>

<script>
import deps from "../../depsloader";

export default {
  name: "Table",

  props: [
    "labels",
    "units"
  ],

  methods: {
    openGallery(link, title) {
      deps.load("lightgallery").then(() => {
         lightGallery(this.$refs.dataTable, {  // eslint-disable-line
              dynamic: true,
              dynamicEl: [{
                  "src": link,
                  'thumb': link,
                  'subHtml': title
              }]
          });
      });
    }
  },


  data() {
    return {
    }
  },
}
</script>
