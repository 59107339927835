<template>
<v-app>
<v-dialog
    v-model="dialog"
    width="688"
    attach="body">
    <v-card>
      <v-card-title
        class="w-100 justify-end"
        primary-title
      >
          <div
            class="team__interview-close-times"><v-btn
            icon
            dark
            @click="closeModal">
            <span class="fal fa-2x fa-times text-secondary"></span>
          </v-btn>
          </div>
      </v-card-title>

      <v-card-text>
        <div v-html="content"></div>

        <contact-form
          class="finanzierungssprechstunde-form"
          name="finanzierungssprechstunde"
          :required-fields="requiredFields"
          :buttonText="$t('Absenden')">
            <contact-fields-finanzierungssprechstunde :exclude="exclude"></contact-fields-finanzierungssprechstunde>
        </contact-form>
      </v-card-text>
    </v-card>
</v-dialog>
</v-app>
</template>

<script>
import ContactForm from "../contact/ContactForm";
import ContactFieldsFinanzierungssprechstunde from '../contact/ContactFieldsFinanzierungssprechstunde.vue';
import deps from "../../depsloader"

export default {
  name: "Modal",

  data() {
    return {
      dialog: false
    }
  },

  components: {
    ContactForm,
    ContactFieldsFinanzierungssprechstunde
  },

  computed: {
    name() {
      return this.$root.name;
    },

    title() {
      return this.$root.title;
    },

    content() {
      return this.$root.content;
    },

    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    },

    exclude() {
        return this.$root.exclude;
    }
  },

  methods: {
    closeModal() {
      deps.load("js-cookie").then( ( Cookies ) => {
        Cookies.set( 'expose_finanzierung_modal', true, { expires: 2 } );
        this.dialog = !this.dialog;
      } );
    }
  },

  mounted() {
    const openButton = document.querySelector("a[href='#" + this.name + "']");
    if (openButton) {
      openButton.addEventListener("click", (e) => {
        e.preventDefault();

        this.dialog = !this.dialog
      });
    }

    //open default
    deps.load("js-cookie").then( ( Cookies ) => {
      let hasFinanzierungCookie = false;
      hasFinanzierungCookie = Cookies.get( 'expose_finanzierung_modal' );

      if ( this.$root.immoExposeId && !hasFinanzierungCookie) {
        this.dialog = !this.dialog;
      }
    } );
  }
}
</script>
