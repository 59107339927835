<template>
  <v-app>
    <div class="immoapp__search" data="app">
      <p class="h3">{{ title }}</p>
      <v-form @submit.prevent="search">
        <v-row class="row-small">
          <v-col cols="24" md lg>
            <v-select
              :items="categories"
              v-model="category"
              :label="$t('Objektart')"
              filled
              flat
              attach
              hide-details
              @change="resetFields"
              :no-data-text="$t('Keine Daten vorhanden')"
            ></v-select>
          </v-col>

          <v-col v-if="mode != 'small'" cols="24" md="8" lg="8">
            <v-select
              :items="availableRooms"
              v-model="numberRooms"
              :label="$t('Zimmer ab')"
              filled
              flat
              clearable
              attach
              hide-details
              :no-data-text="$t('Keine Daten vorhanden')"
            ></v-select>
          </v-col>

          <v-col cols="24" md="8" lg="8">
            <v-select
              :items="availablePrices"
              v-model="price"
              :label="$t('Preis bis')"
              filled
              flat
              clearable
              attach
              hide-details
              :no-data-text="$t('Keine Daten vorhanden')"
            ></v-select>
          </v-col>

          <v-col cols="24" md="11" lg="11" class="loc">
            <v-select
              :items="availableLocations"
              v-model="city"
              :label="$t('Standort')"
              filled
              flat
              clearable
              attach
              hide-details
              :no-data-text="$t('Keine Daten vorhanden')"
            ></v-select>
          </v-col>

          <v-col cols="24" md="10" lg="10" class="loc">
            <v-select
              :items="availableStreets"
              v-model="street"
              :label="$t('Straße')"
              filled
              flat
              clearable
              attach
              hide-details
              :no-data-text="$t('Keine Daten vorhanden')"
            ></v-select>
          </v-col>

          <v-col cols="24" md="2" lg="2" class="search-btn-col">
            <button
              type="submit"
              :title="$t('Suchen')"
              class="btn btn-nomw btn-block btn-lg btn-secondary btn-search"
            >
              <i class="far fa-search"></i>
            </button>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-app>
</template>

<script>
// import GeocoderAutocomplete from "./utils/GeocoderAutocomplete";
import inquiryFieldsConfig from "./utils/InquiryFieldsConfig";
import config from "./ConfigSearch";

export default {
  name: "SearchApp",

  props: ["data", "params", "action", "mode"],

  components: {
    // GeocoderAutocomplete,
  },

  data: () => ({
    marketingType: "",
    category: null,
    numberRooms: null,
    price: null,
    radius: null,
    customObjectId: null,
    categoryId: null,
    livingSpace: null,
    plotArea: null,
    totalArea: null,
    street: "",
    address: "",
    addressModel: "",
    lat: null,
    lng: null,
    city: null,
    zip: null,
    sort: null,
    marketingTypes: [
      {
        text: "Kauf",
        value: "buy",
      },
      {
        text: "Miete",
        value: "rent",
      },
    ],
    radiusList: [
      {
        text: "5 km",
        value: 5,
      },
      {
        text: "10 km",
        value: 10,
      },
      {
        text: "15 km",
        value: 15,
      },
      {
        text: "25 km",
        value: 25,
      },
      {
        text: "35 km",
        value: 35,
      },
    ],
    fields: [],
    fieldsConfig: [],
    config,
  }),

  computed: {
    detailFields() {
      let fields = this.fields;
      for (let i in fields) {
        var value = "value";
        var name = fields[i].id;
        if (fields[i]["range"] == "max") {
          value = "maxValue";
        } else if (fields[i]["range"] == "min") {
          value = "minValue";
        }

        if (name == "price.value") {
          name = "price";
        } else {
          name = name.substr(name.indexOf(".") + 1);
        }

        fields[i]["ds"][value] = this[name];
      }

      return fields;
    },

    selectedFields() {
      if (this.category) {
        let fieldNames = [];
        config.categoryList.forEach((c) => {
          if (c.name == this.category.slug) {
            c.fieldList.forEach((f) => {
              if (typeof f == "object") {
                fieldNames.push(f.var);
              } else {
                fieldNames.push(f);
              }
            });
          }
        });

        return this.detailFields.filter((f) => {
          return fieldNames.indexOf(f.id) > -1;
        });
      }

      return this.detailFields.slice(0, 4);
    },

    categories() {
      let categories = [];
      for (let i in this.data) {
        categories.push({
          text: this.data[i].displayName,
          value: this.data[i].termId,
        });
      }

      return categories;
    },

    title() {
      let title = this.$t("Schnellsuche");
      if (this.mode != "small") {
        for (let i in this.data) {
          if (this.data[i].termId == this.category) {
            title = this.data[i]["title"];
          }
        }
      }

      return title;
    },

    availableRooms() {
      let rooms = [];
      for (let i in this.data) {
        if (this.data[i].termId == this.category) {
          for (let j in this.data[i]["rooms"]) {
            rooms.push({
              text: this.data[i]["rooms"][j],
              value: parseInt(this.data[i]["rooms"][j]),
            });
          }
        }
      }

      return rooms;
    },

    availableStreets() {
      let streets = [];
      for (let i in this.data) {
        if (this.data[i].termId == this.category) {
          streets = this.data[i]["locations_streets"];
        }
      }

      return streets;
    },

    availableLocations() {
      let locations = [];
      for (let i in this.data) {
        if (this.data[i].termId == this.category) {
          for (let j in this.data[i]["locations"]) {
            locations.push({
              text: this.data[i]["locations"][j],
              value: this.data[i]["locations"][j],
            });
          }
        }
      }

      return locations;
    },

    availablePrices() {
      let price = [];
      for (let i in this.data) {
        if (this.data[i].termId == this.category) {
          for (let j in this.data[i]["price"]) {
            price.push({
              text: this.$n(this.data[i]["price"][j], "currency"),
              value: this.data[i]["price"][j],
            });
          }
        }
      }

      return price;
    },

    actionUrl() {
      let actionUrl = this.action;
      for (let i in this.data) {
        if (this.data[i].termId == this.category) {
          actionUrl = this.data[i]["url"];
        }
      }

      return actionUrl;
    },
  },

  mounted() {
    Object.keys(this.params).forEach((k) => {
      if (this.params[k].value) {
        this[k] = this.params[k].value;
      }
    });

    for (let i in this.data) {
      if (this.data[i].active) {
        this.category = this.data[i].termId;
      }
    }

    this.addressModel = {
      text: this.address,
      value: this.address,
      zip: this.zip,
      city: this.city,
      lat: this.lat,
      lng: this.lng,
    };

    inquiryFieldsConfig(config, (t) => {
      return t;
    }).then((fieldsConfig) => {
      this.fields = fieldsConfig;
    });
  },

  methods: {
    changeField(field) {
      //const fields = [];
      let fields = this.selectedFields;
      var name = null;
      var value = null;

      for (let i in fields) {
        if (fields[i].id == field.id) {
          name = field.id;
          if (fields[i]["range"] == "max") {
            value = fields[i]["ds"]["maxValue"];
          } else if (fields[i]["range"] == "min") {
            value = fields[i]["ds"]["minValue"];
          }

          if (name == "price.value") {
            name = "price";
          } else {
            name = name.substr(name.indexOf(".") + 1);
          }
        }
      }

      this[name] = value;
    },

    resetFields() {
      this.price = null;
      this.numberRooms = null;
      this.city = null;
      this.street = null;
    },

    updateGeo(geo) {
      if (geo) {
        this.lat = geo.lat;
        this.lng = geo.lng;
        this.address = geo.text;
        this.zip = geo.zip;
        this.city = geo.city;

        if (!this.radius) {
          this.radius = this.radiusList[0].value;
        }
      } else {
        this.lat = null;
        this.lng = null;
        this.address = null;
        this.radius = null;
        this.zip = null;
        this.city = null;
      }
    },

    search() {
      const params = new URLSearchParams();
      Object.keys(this.params).forEach((k) => {
        if (k != "paged") {
          if (this[k] || this[k] === "") {
            if (k == "sort") {
              params.append(this.params[k].name, this[k].field + "|" + this[k].order.toLowerCase());
            } else {
              params.append(this.params[k].name, this[k]);
            }
          }
        }
      });

      window.location.href = this.actionUrl + "?" + params.toString() + "#offers";
    },
  },
};
</script>
