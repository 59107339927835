<template>
  <div class="suchprofil-form">
    <div>
      <table class="project-form-listing__table text-xsmall">
        <tr class="mb-2 grid-4-cols mb-md-1">
          <th></th>
          <th>Objektnummer</th>
          <th>Kategorie</th>
          <th>Adresse</th>
        </tr>

        <tr v-for="( item, key ) of getProjectListing" :key="key" class="mb-2 grid-4-cols mb-md-1">
          <td class="d-flex">
            <contact-group-checkbox :name="'Projekt-' + item.customObjectId" group="contact" :values="[ 'Ja' ]" hidden></contact-group-checkbox>
            <img :src="item.image" :alt="item.customObjectId" class="project-form-listing__image" />
          </td>

          <td>
            {{ item.customObjectId }}
          </td>

          <td>
            {{ item.category }}
          </td>

          <td>
            {{ item.street }}<br />{{ item.zip }} {{ item.city }}
          </td>
        </tr>
      </table>
    </div>


    <div class="mt-3">
      <h2>Ihre persönlichen Daten</h2>
    </div>

    <div class="row row-small" v-if="exclude.indexOf('person') == -1">
      <div class="col-md-12">
        <contact-select name="salutation" :label="$t('Anrede')" group="contact" :values="$t('Frau,Herr')" :placeholder="$t('Anrede')"></contact-select>
      </div>
      <div class="col-md-12">
        <contact-input name="title" :label="$t('Titel')" group="contact"></contact-input>
      </div>
    </div>

    <div class="row row-small">
      <div class="col-md-12">
        <contact-input name="firstname" :label="$t('Vorname')" group="contact"></contact-input>
      </div>
      <div class="col-md-12">
        <contact-input name="lastname" :label="$t('Nachname')" group="contact"></contact-input>
      </div>
    </div>

    <div class="row row-small" v-if="exclude.indexOf('phone_email') == -1">
      <div class="col-12">
        <contact-input name="phone" :label="$t('Telefon')" group="contact"></contact-input>
      </div>
      <div class="col-12" v-if="exclude.indexOf('email') == -1">
        <contact-input name="email" :label="$t('E-Mail')" group="contact"></contact-input>
      </div>
    </div>

    <div class="row row-small">
      <div class="col-24 col-md-10">
        <contact-input name="street" :label="$t('Straße')" group="contact"></contact-input>
      </div>
      <div class="col-12 col-md-7">
        <contact-input name="zip" :label="$t('PLZ')" group="contact"></contact-input>
      </div>
      <div class="col-12 col-md-7">
        <contact-input name="city" :label="$t('Ort')" group="contact"></contact-input>
      </div>
    </div>

    <div class="row" v-if="exclude.indexOf('contactType') == -1">
      <div class="col-sm-24">
        <v-radio-group v-model="contactType" row :label="$t('Kontakt per:')" class="py-0 my-0" :hide-details="true">
          <v-radio hide-details :label="$t('E-Mail')" value="E-Mail" off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
          <v-radio hide-details :label="$t('Telefon')" value="Telefon" off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
        </v-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
  import ContactInput from "./ContactInput.vue";
  import ContactSelect from "./ContactSelect.vue";
  import ContactGroupCheckbox from "./ContactGroupCheckbox.vue";

  export default {
    name: "ContactFieldsProjectSelection",

    components: {
      ContactInput,
      ContactSelect,
      ContactGroupCheckbox
    },

    props: {
      include: {
        type: Array,
        default: () => []
      },
      exclude: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        contactType: []
      };
    },

    computed: {
      fields() {
        if (!this.include) {
          return [];
        }

        return [...this.include];
      },

      getProjectListing() {
        if ( window.projectListing ) {
          return window.projectListing;
        }

        return null;
      }
    },

    mounted() {
      this.$root.contactForm.registerField(this);
    },

    beforeDestroy() {
      this.$root.contactForm.unregisterField(this);
    },

    methods: {
      mapValue() {
        this.$root.contactForm.contact.contactType = this.contactType;
      }
    }
  };
</script>
