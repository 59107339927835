import Vue from "vue";
import vuetify from "../vuetify.js";
import depsloader from "../depsloader";
import EventsContactApp from "../components/contact/EventsContactApp.vue";

export default function() {
  const element = document.querySelector("#vue-events");

  if (element) {
    depsloader.load("vue-i18n").then((vueI18n) => {
      Vue.use(vueI18n.VueI18n);

      new Vue({
        el: element,

        components: {
          EventsContactApp
        },

        computed: {
          config() {
            if (element.dataset.config) {
              return JSON.parse(element.dataset.config);
            }

            return null;
          }
        },

        vuetify,
        i18n: new vueI18n.VueI18n(vueI18n.options),

        render: h => h(EventsContactApp)
      })
    });
  }
}
