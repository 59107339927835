<template>
<div class="pb-2">
<v-app>
  <contact-form :name="name" :buttonText="$t('Kundenstimme abgeben')" :requiredFields="requiredFields">
      <testimonial-form></testimonial-form>
      <template slot="disclaimer">
        {{ $t('Mit der Erfassung, Speicherung, Verwendung und Veröffentlichung meiner Bewertung bin ich einverstanden.') }}
        {{ $t('Hinweis: Sie können Ihre Einwilligung jederzeit widerrufen.') }}
      </template>
  </contact-form>
</v-app>
</div>
</template>

<script>
import ContactForm from "./ContactForm.vue";
import TestimonialForm from "./TestimonialForm.vue";

export default {
  name: "TestimonialApp",

  components: {
    ContactForm,
    TestimonialForm
  },

  data() {
    return {
      name: "testimonials",
      visible: false
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    }
  },

  methods: {
  },

  mounted() {
    const openButton = document.querySelector("a[href='#bewerten']");
    if (openButton) {
      openButton.addEventListener("click", (e) => {
        e.preventDefault();

        this.visible = true;
      });
    }
  }
};
</script>
