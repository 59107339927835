<template>
<div :class="wrapperClassNames">
  <div v-for="(item, key) of values" :key="key">
    <v-checkbox
      v-model="val"
      :label="getLabel(item)"
      :value="item"
      :error="!!error"
      :error-messages="error"
      hide-details
      outlined
      filled
    ></v-checkbox>
  </div>
</div>
</template>

<script>
export default {
  name: "ContactGroupCheckbox",

  props: [
    "name",
    "group",
    "values",
    "wrapperClassNames",
    "hidden"
  ],

  data() {
    return {
      val: []
    };
  },

  mounted() {
    if (this.group == "contact" && this.$root.contactForm.contact[this.name]) {
      this.val = this.$root.contactForm.contact[this.name];
    }

    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  computed: {
    invalid() {
      return this.$root.contactForm.errors.indexOf(this.name) > -1;
    },

    required() {
      return this.$root.contactForm.isRequired(this.name);
    },

    error() {
      if (this.invalid) {
        return "Pflichtfeld";
      }

      return null;
    },

    id() {
      return `contact__${this.name}`;
    }
  },

  methods: {
    mapValue() {
      if (this.group == "contact") {
        this.$root.contactForm.contact[this.name] = this.val;
      }
    },

    getLabel( item ) {
      if ( this.hidden != undefined ) {
        return "";
      }

      return item;
    },

    setValue( ) {
      // console.log(val);
    }
  }
};
</script>
