import deps from "../depsloader";

export default () => {
  const elms = document.querySelectorAll(".lightgallery, .blocks-gallery-grid");
  const videoElms = document.querySelectorAll(".videolink");
  if ((!elms || elms.length == 0) && (!videoElms || videoElms.length == 0)) {
    return;
  }

  deps.load("lightgallery").then(() => {
    if (videoElms.length > 0) {
      for (let element of videoElms) {
        let config = {
          selector: 'this',
          iframeMaxWidth: '80%',
          videoMaxWidth: '60%',
          download: false
        };

        lightGallery(element, config); // eslint-disable-line
      }
    }

    if (elms.length > 0) {
      for (let element of elms) {
        let config = {
          "download": false,
          "selector": "a"
        };

        if (element.dataset.config) {
          config = element.dataset.config;
          element.removeAttribute("data-config");
          config = JSON.parse(config)
        }

        lightGallery(element, config); // eslint-disable-line
      }
    }

    const exposeBtnList = document.querySelectorAll(".exGalleryBtn");
    if (exposeBtnList) {
      for (let exposeBtn of exposeBtnList) {
        exposeBtn.addEventListener("click", function(e) {
          e.preventDefault();

          const gallery = document.querySelector("#exGallery");
          if (gallery) {
            gallery.firstElementChild.click();
            gallery.firstElementChild.click();
          }
        });
      }
    }
  });
}
