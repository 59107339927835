<template>
<div>

  <div class="row row-small">
    <div class="col-14">
      <contact-input name="email" :label="$t('E-Mail')" group="contact"></contact-input>
    </div>
    <div class="col-24 col-md-5">
      <contact-select name="salutation" :label="$t('Anrede')" group="contact" :values="$t('Frau,Herr,Divers')" :placeholder="$t('Anrede')"></contact-select>
    </div>
    <div class="col-24 col-md-5">
      <contact-input name="title" :label="$t('Titel')" group="contact"></contact-input>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-md-12">
      <contact-input name="firstname" :label="$t('Vorname')" group="contact"></contact-input>
    </div>
    <div class="col-md-12">
      <contact-input name="lastname" :label="$t('Nachname')" group="contact"></contact-input>
    </div>
  </div>

</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactSelect from "./ContactSelect.vue";

export default {
  name: "ContactFieldsNewsletter",

  components: {
    ContactInput,
    ContactSelect
  },

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      contactType: []
    };
  },

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    }
  },

  mounted() {
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      this.$root.contactForm.contact.contactType = this.contactType;
    }
  }
};
</script>
