<template>
<div>
  <div class="testimonial__listing">
    <div class="testimonial__listing__anker" ref="listingAnker"></div>

    <div class="wp-block-columns is-style-gutter">
      <div v-for="testimonial in testimonials"
          :key="testimonial.id"
          class="wp-block-column mb-3">
        <div class="position-relative">
          <div v-html="testimonial.html"></div>

          <v-modal v-if="testimonial.commentByCustomer"
                   title="Unser Kommentar"
                   :content="testimonial.commentByCustomer"
                   :ref="'t' + testimonial.id">
          </v-modal>
          <a v-if="testimonial.commentByCustomer"
             class="btn testimonials__btn-comment"
             href="#"
             @click.prevent="showModal(testimonial.id)"
             title="Kommentar von uns">
              Unser Kommentar
          </a>
        </div>
      </div>
    </div>

    <div v-if="pages && pages > 1" class="pagination text-center mb-4 mt-1">
      <a class="prev page-numbers"
        @click.prevent="prev"><span class="fal fa-chevron-left"></span></a>

      <a class="page-numbers"
        v-for="p in pages"
        :key="p"
        @click.prevent="open(p);"
        v-bind:class="{ current: p == activePage }">
        {{ p }}
      </a>

      <a class="next page-numbers"
        @click.prevent="next"><span class="fal fa-chevron-right"></span></a>
    </div>
  </div>
</div>
</template>

<script>
import VModal from "./VModal.vue";

export default {
  name: "Archive",

  components: {
    VModal
  },

  data() {
    return {
      testimonials: [],
      page: 1,
      pages: null,
      activePage: null,
      firstRun: true,
      backend:""
    }
  },

  props: [
    "ignorePosts"
  ],

  computed: {
    scrollTopPosition() {
      var yPosition = 0;
      var element = this.$refs.listingAnker;
      while(element) {
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
      }

      return yPosition;
    }
  },

  methods: {
    load() {
      if (this.page == this.activePage) {
        return;
      }

      let params = this.getParams();
      this.$http.get(this.backend + "/wp-json/rest/testimonials/v1/archive", {
          params: params
      }).then((response) => {
        this.page = response.data.page;
        this.activePage = response.data.page;
        this.pages = response.data.pages
        this.testimonials = response.data.entries;

        this.$nextTick(() => {
          this.$yeti.init_readmore();


          const fakebtns = document.querySelectorAll(".readmore__trigger");
          fakebtns.forEach((item) => {
            item.onclick = (e) => {
              if (!this.$yeti.isTouchDevice) {
                return true;
              }
              e.preventDefault();

              var trigger = document.getElementById(item.getAttribute("for"));

              trigger.checked = !trigger.checked;
              trigger.onchange();
            }
          });

          if (!this.firstRun) {
            this.scrollToTop();
            var searchParameters = new URLSearchParams();
            Object.keys(params).forEach((parameterName) => {
              if (this.$options._propKeys.indexOf(parameterName) == -1) {
                searchParameters.append(parameterName, params[parameterName]);
              }
            });

            window.location.hash = searchParameters;
          }

          this.firstRun = false;
        });
      });
    },

    getParams() {
      return {
        page: this.page,
        ignorePosts:this.ignorePosts
      }
    },

    scrollToTop() {
      window.scrollTo({
        top: this.scrollTopPosition,
        left: 0,
        behavior: 'smooth'
      });
    },

    open(p) {
      this.page = p;
      this.load();
    },

    prev() {
      this.page--;
      if (this.page < 1) {
        this.page = 1;
      }

      this.load();
    },

    next() {
      this.page++;
      if (this.pages && this.page > this.pages) {
        this.page = this.pages;
      }

      this.load();
    },

    showModal(val) {
      this.$refs['t' + val][0].dialog = true;
    }
  },

  mounted() {
    const htmlLang = document.querySelector("html").getAttribute("lang");
    if (htmlLang == "en-US") {
      this.backend = "/en";
    }

    (new URLSearchParams(window.location.hash.replace("#", ""))).forEach((value, key) => {
      if (typeof this[key] !== "undefined" && value != "") {
        if (typeof this[key] == "object") {
          this[key] = value.split(",").map(id => parseInt(id)).filter((v, i, a) => a.indexOf(v) === i);
        } else if (typeof this[key] == "number") {
          this[key] = parseInt(value);
        }
      }
    });

    this.load();
  }
}
</script>
