<template>
<div>
  <div class="row row-small">
    <div class="col-md-24">
      <contact-select name="timeWindow" :label="$t('Zeitfenster')" group="contact" :values="$t('11-12 Uhr|11-12 Uhr, 12-13 Uhr|12-13 Uhr, 13-14 Uhr|13-14 Uhr, 14-15 Uhr|14-15 Uhr')" :placeholder="$t('Zeitfenster')"></contact-select>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-md-8">
      <contact-select name="salutation" :label="$t('Anrede')" group="contact" :values="$t('Frau,Herr,Divers')" :placeholder="$t('Anrede')"></contact-select>
    </div>

    <div class="col-md-8">
      <contact-input name="firstname" :label="$t('Vorname')" group="contact"></contact-input>
    </div>

    <div class="col-md-8">
      <contact-input name="lastname" :label="$t('Nachname')" group="contact"></contact-input>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-md-12">
      <contact-input name="phone" :label="$t('Telefon')" group="contact"></contact-input>
    </div>

    <div class="col-md-12">
      <contact-input name="email" :label="$t('E-Mail')" group="contact"></contact-input>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-24 col-md-12">
      <contact-input name="street" :label="$t('Straße')" group="contact"></contact-input>
    </div>
    <div class="col-12 col-md-6">
      <contact-input name="zip" :label="$t('PLZ')" group="contact"></contact-input>
    </div>
    <div class="col-12 col-md-6">
      <contact-input name="city" :label="$t('Ort')" group="contact"></contact-input>
    </div>
  </div>

  <div class="row" v-if="exclude.indexOf('message') == -1">
    <div class="col-sm-24">
      <contact-input textarea :label="$t('Nachricht')" name="message" group="contact"></contact-input>
    </div>
  </div>

  <div class="row" v-if="exclude.indexOf('contactType') == -1">
    <div class="col-sm-24">
      <v-radio-group v-model="contactType" row :label="$t('Kontakt per:')" class="my-0 py-0" :hide-details="true">
        <v-radio hide-details :label="$t('E-Mail')" value="E-Mail" off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
        <v-radio hide-details :label="$t('Telefon')" value="Telefon" off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
      </v-radio-group>
    </div>
  </div>
</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactSelect from "./ContactSelect.vue";

export default {
  name: "ContactFieldsFinanzierungssprechstunde",

  components: {
    ContactInput,
    ContactSelect
  },

  data() {
    return {
      contactType: null
    }
  },

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    },

    objectId() {
      return this.$root.objectId;
    },

    config() {
      return this.$root.config;
    },
  },

  mounted() {
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      this.$root.contactForm.contact.contactType = this.contactType;
      this.$root.contactForm.immoExposeId = this.$root.immoExposeId;
    }
  }
};
</script>
