<template>
  <v-radio-group
      v-model="value"
      row
    >
      <v-radio
        label="Sehr gut"
        value="sehr-gut"
      ></v-radio>
      <v-radio
        label="Gut"
        value="gut"
      ></v-radio>
      <v-radio
        label="Befriedigend"
        value="befriedigend"
      ></v-radio>
      <v-radio
        label="Ausreichend"
        value="ausreichend"
      ></v-radio>
      <v-radio
        label="Ungenügend"
        value="ungenügend"
      ></v-radio>
      <v-radio
        label="Mangelhaft"
        value="mangelhaft"
      ></v-radio>
  </v-radio-group>
</template>

<script>
export default {
  name: "KundenbewertungRadioButtons",

  props: [
    "name",
    "group",
  ],

  data() {
    return {
      value: ""
    }
  },

  mounted() {
    if (this.group == "contact") {
      this.value = this.$root.contactForm.contact[this.name];
    }
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      if (this.group == "contact") {
        this.$root.contactForm.contact[this.name] = this.value;
      }
    }
  }
}
</script>

