<template>
<v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title
        class="headline bg-black w-100"
        primary-title
      >
          {{ title }}
          <v-spacer></v-spacer>
          <div
            class="float-right"><v-btn
            icon
            dark
            @click="dialog = false">
            <span class="fal fa-2x fa-times"></span>
          </v-btn>
          </div>
      </v-card-title>

      <v-card-text class="position-relative">
        <p class="h6 mt-1">{{ content }}</p>
      </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
export default {
  name: "modal",

  props: {
    title: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      dialog: false
    }
  }
}
</script>
