import Vue from "vue";
import vuetify from "../vuetify.js";
import depsloader from "../depsloader";
import SuchprofilApp from "../components/contact/SuchprofilApp.vue";

export default function() {
  const element = document.querySelector("#vue-suchprofil-mim");

  if (element) {
    depsloader.load("vue-i18n").then((vueI18n) => {
      Vue.use(vueI18n.VueI18n);

      new Vue({
        el: element,

        components: {
          SuchprofilApp
        },

        data() {
          return {
            includeFields: [ 'mim2023' ]
          }
        },

        vuetify,
        i18n: new vueI18n.VueI18n(vueI18n.options),

        render: h => h(SuchprofilApp)
      })
    });
  }
}
