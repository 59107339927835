import Modal from "../components/immo/ModalOffMarketInvestment.vue";
import ImmoappLoader from "../misc/ImmoappLoader";

export default () => {
  const elms = document.querySelectorAll(".vue-immo-offmarket-investment-modal");
  if (!elms || elms.length == 0) {
    return;
  }

  for (let element of elms) {
    ImmoappLoader(element, Modal, {
      store: true,
      props: {
        exposeId: element.dataset.id,
        title: element.dataset.title,
        customObjectId: element.dataset.cid
      }
    });
  }
};
