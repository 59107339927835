<template>
<div>
  <div class="row row-small">
    <div class="col-md-24">
      <contact-select name="subject" :label="$t('Betreff wählen')" group="contact" :values="$t('Grundstück anbieten,Tipp abgeben')" :placeholder="$t('Betreff wählen')"></contact-select>
    </div>
  </div>

  <div class="row row-small" v-if="exclude.indexOf('person') == -1">
    <div class="col-md-7">
      <contact-select name="salutation" :label="$t('Anrede')" group="contact" :values="$t('Frau,Herr')" :placeholder="$t('Anrede')"></contact-select>
    </div>

    <div class="col-md-17">
      <div class="row row-small">
        <div class="col-md-12">
          <contact-input name="firstname" :label="$t('Vorname')" group="contact"></contact-input>
        </div>
        <div class="col-md-12">
          <contact-input name="lastname" :label="$t('Nachname')" group="contact"></contact-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row row-small" v-if="exclude.indexOf('phone_email') == -1">
    <div class="col-12">
      <contact-input name="phone" :label="$t('Telefon')" group="contact"></contact-input>
    </div>
    <div class="col-12" v-if="exclude.indexOf('email') == -1">
      <contact-input name="email" :label="$t('E-Mail')" group="contact"></contact-input>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-24 col-md-10">
      <contact-input name="street" :label="$t('Straße')" group="contact"></contact-input>
    </div>
    <div class="col-12 col-md-7">
      <contact-input name="zip" :label="$t('PLZ')" group="contact"></contact-input>
    </div>
    <div class="col-12 col-md-7">
      <contact-input name="city" :label="$t('Ort')" group="contact"></contact-input>
    </div>
  </div>


  <div class="row" v-if="fields.indexOf('contactReason') > -1">
    <div class="col-sm-24">
      <contact-select
        name="contactReason"
        :label="$t('Ihr Wunsch')"
        group="contact"
        :values="$t('Besichtigung|besichtigung, Anruf|anruf, Weitere Details|detail')"
      ></contact-select>
    </div>
  </div>

  <div class="row" v-if="exclude.indexOf('message') == -1">
    <div class="col-sm-24">
      <contact-input  textarea :label="$t('Nachricht')" name="message" group="contact"></contact-input>
    </div>
  </div>

  <div class="row" v-if="exclude.indexOf('contactType') == -1">
    <div class="col-sm-24">
      <v-radio-group v-model="contactType" row :label="$t('Kontakt per:')" class="my-0 py-0" :hide-details="true">
        <v-radio hide-details :label="$t('E-Mail')" value="E-Mail" off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
        <v-radio hide-details :label="$t('Telefon')" value="Telefon" off-icon="fas fa-square" on-icon="fas fa-check-square"></v-radio>
      </v-radio-group>
    </div>
  </div>
</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactSelect from "./ContactSelect.vue";

export default {
  name: "ContactFieldsTipp",

  components: {
    ContactInput,
    ContactSelect
  },

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      contactType: []
    };
  },

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    }
  },

  mounted() {
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      this.$root.contactForm.contact.contactType = this.contactType;
    }
  }
};
</script>
