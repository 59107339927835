<template>
<div>

  <div>
    <p><span class="h4">1. Erreichbarkeit:</span> Wie erreichbar war unser Team für Sie?</p>
    <contact-fields-kundenbewertung-radios group="contact" name="erreichbarkeit"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">2. Reaktionszeit:</span> Wie haben wir auf Ihre Anfragen, Rückruf-Bitten etc. reagiert?</p>
    <contact-fields-kundenbewertung-radios group="contact" name="reaktionszeit"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">3. Beratungsleistung:</span> Wie hat Ihr persönlicher Kundenberater Sie beraten?</p>
    <contact-fields-kundenbewertung-radios group="contact" name="beratungsleistung"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">4. Service nach dem Notartermin:</span> Wie war unsere Betreuung nach dem Notartermin?</p>
    <contact-fields-kundenbewertung-radios group="contact" name="serviceNotartermin"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">5. Vertriebsunterlagen:</span> Wie gut waren die Vertriebsunterlagen aufbereitet?</p>
    <contact-fields-kundenbewertung-radios group="contact" name="vertriebsunterlagen"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">6. Generalunternehmer:</span> Wie hat der Generalunternehmer Sie betreut?</p>
    <contact-fields-kundenbewertung-radios group="contact" name="generalunternehmer"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">7. Bau-Qualität:</span> Wie zufrieden sind Sie mit der Bau-Qualität Ihrer Immobilie?</p>
    <contact-fields-kundenbewertung-radios group="contact" name="bauqualität"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">8. Erwartungen:</span> Wurden Ihre Erwartungen an die fertiggestellte Immobilie erfüllt?</p>
    <contact-fields-kundenbewertung-radios group="contact" name="erwartungen"></contact-fields-kundenbewertung-radios>
  </div>

  <div>
    <p><span class="h4">9. Reklamation:</span> Hatten Sie Grund für Reklamationen / Beschwerden?</p>
    <v-radio-group
      v-model="reklamation"
      row
    >
      <v-radio
        label="Ja"
        value="ja"
      ></v-radio>
      <v-radio
        label="Nein"
        value="nein"
      ></v-radio>
    </v-radio-group>

    <p class="mt-n1">Wenn ja, wurden Ihre Probleme gelöst?</p>
    <v-radio-group
      v-model="weiterempfehlung"
      row
    >
      <v-radio
        label="Ja"
        value="ja"
      ></v-radio>
      <v-radio
        label="Nein"
        value="nein"
      ></v-radio>
    </v-radio-group>
  </div>

  <div>
    <p><span class="h4">10. Weiterempfehlung:</span> Würden Sie uns weiterempfehlen?</p>
    <v-radio-group
      v-model="reklamationGeloest"
      row
    >
      <v-radio
        label="Ja"
        value="ja"
      ></v-radio>
      <v-radio
        label="Nein"
        value="nein"
      ></v-radio>
    </v-radio-group>
  </div>

  <div>
    <contact-input textarea :label="$t('Hier ist Platz für Ihr Kommentar...')" name="message" group="contact"></contact-input>
  </div>

</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactFieldsKundenbewertungRadios from "./ContactFieldsKundenbewertungRadios.vue";

export default {
  name: "ContactFieldsNewsletter",

  components: {
    ContactInput,
    ContactFieldsKundenbewertungRadios
  },

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      reklamation: "",
      reklamationGeloest: "",
      weiterempfehlung: "",
    };
  },

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    }
  },

  mounted() {
    this.$root.contactForm.registerField(this);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("kdnr")) {
      this.$root.contactForm.contact.kdnr = urlParams.get("kdnr");
    }

    if (urlParams.get("email")) {
      this.$root.contactForm.contact.email = urlParams.get("email");
    }
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      this.$root.contactForm.contact.reklamation = this.reklamation;
      this.$root.contactForm.contact.reklamationGeloest = this.reklamationGeloest;
      this.$root.contactForm.contact.weiterempfehlung = this.weiterempfehlung;
    }
  }
};
</script>
