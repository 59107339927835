import Vue from "vue";
import vuetify from "../vuetify.js";
import depsloader from "../depsloader";
import Modal from "../components/modal/Modal.vue";
import TeamInterview from "../components/modal/TeamInterview.vue";
import Finanzierungssprechstunde from "../components/modal/Finanzierungssprechstunde.vue";

export default () => {
  const elements = document.querySelectorAll(".vue-modal");

  if (elements) {
    depsloader.load("vue-i18n").then((vueI18n) => {
      Vue.use(vueI18n.VueI18n);

      elements.forEach((element) => {
        let entry = Modal;

        if (element.dataset.entry && element.dataset.entry == "team-interview") {
          entry = TeamInterview;
        } else if (element.dataset.entry && element.dataset.entry == "finanzierungssprechstunde") {
          entry = Finanzierungssprechstunde;
        }

        new Vue({
          el: element,

          computed: {
            name() {
              let name = "";
              if (element.dataset.name) {
                name = element.dataset.name;
              }

              return name;
            },

            title() {
              let title = "";
              if (element.dataset.title) {
                title = element.dataset.title;
              }

              return title;
            },

            content() {
              let content = {};
              if (element.dataset.content) {
                content = JSON.parse(element.dataset.content);
              }

              return content;
            },

            immoExposeId() {
              if (element.dataset.immoexposeid) {
                return element.dataset.immoexposeid;
              }

              return null;
            },

            exclude() {
                if (element.dataset.exclude) {
                    return JSON.parse(element.dataset.exclude);
                }

                return null;
            }
          },

          vuetify,
          i18n: new vueI18n.VueI18n(vueI18n.options),

          render: h => h(entry)
        })
      });
    });
  }
};
