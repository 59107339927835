// ACHTUNG!! Muss JSON sein, keine Komentare!
export default {
  "entrypoints": {
    "navigation": {"php": false},
    "contact": {"php": false},
    "tipp": {"php": false},
    "newsletter": {"php": false},
    "events": {"php": false},
    "cookie-notice": {"php": false},
    "swiper": {"php": false},
    "accordion": {"php": false},
    "lightgallery": {"php": false},
    "immo-expose": {"php": false},
    "immo-preview": {"php": false},
    "immoapp": {"php": false},
    "immoapp-savedsearch": {"php": false},
    "immoapp-leadwizard": {"php": false},
    "immoapp-exposecontact": {"php": false},
    "immoapp-search": {"php": false},
    "immoapp-finder": {"php": false},
    "immo-offmarket-investment-modal": {"php": false},
    "tabs": {"php": false},
    "modal": {"php": false},
    "map": {"php": false},
    "counter": {"php": false},
    "immo-listing": {"php": false},
    "testimonial-app": {"php": false},
    "testimonial-archive": {"php": false},
    "table": {"php": false},
    "kundenbewertung": {"php": false},
    "eventsBewertung": {"php": false},
    "iframe-resizer": {"php": false},
    "contact-finanzierung": {"php": false},
    "contact-suchprofil": {"php": false},
    "contact-suchprofil-mim": {"php": false},
    "contact-project-listing": {"php": false},
    "sal-animation": {"php": false},
    "contact-boarding-haus": { "php": false }
  }
}
