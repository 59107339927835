<template>
<div></div>
</template>

<script>
export default {
  name: "ContactInputHidden",

  props: [
    "name",
    "group",
    "value"
  ],

  mounted() {
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      if (this.group == "contact") {
        this.$root.contactForm.contact[this.name] = this.value;
      }
    }
  }
};
</script>
