var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{ref:"dataTable",staticClass:" "},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.labels,"items":_vm.units,"hide-default-footer":"","disable-pagination":"","mobile-breakpoint":900},scopedSlots:_vm._u([{key:"item.plot",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.openGallery(item.plot, item.title)}}},[_c('i',{staticClass:"fal fa-search"})])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [(item.statusHomepage == 'nicht verfügbar')?_c('span',[_vm._v(" "+_vm._s(item.price)+" ")]):_c('span',[(item.link)?_c('a',{attrs:{"href":item.link}},[_vm._v(" "+_vm._s(_vm.$t("Zum Online-Exposé"))+" ")]):_vm._e()])]}},{key:"item.livingSpace",fn:function(ref){
var item = ref.item;
return [(item.livingSpace)?_c('span',[_vm._v(" ca. "+_vm._s(_vm.$n(item.livingSpace, "decimal"))+" m² ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("Keine Angabe"))+" ")])]}},{key:"item.totalSpace",fn:function(ref){
var item = ref.item;
return [(item.totalSpace)?_c('span',[_vm._v(" ca. "+_vm._s(_vm.$n(item.totalSpace, "decimal"))+" m² ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("Keine Angabe"))+" ")])]}},{key:"item.gardenArea",fn:function(ref){
var item = ref.item;
return [(item.gardenArea)?_c('span',[_vm._v(" ca. "+_vm._s(_vm.$n(item.gardenArea, "decimal"))+" m² ")]):_vm._e()]}},{key:"item.customObjectId",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"id":item.customObjectId}},[_vm._v(_vm._s(item.customObjectId))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [('nicht verfügbar' == item.statusHomepage)?_c('span'):_c('span',{domProps:{"innerHTML":_vm._s(isNaN(item.price) ? item.price : _vm.$n(item.price, 'currency'))}})]}},{key:"item.additional_costs",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.additional_costs ? _vm.$n(item.additional_costs, 'currency') : '-')}})]}},{key:"item.car",fn:function(ref){
var item = ref.item;
return [(item.carprice)?_c('span',{domProps:{"innerHTML":_vm._s(item.carprice ? _vm.$n(item.carprice, 'currency') : '-')}}):_vm._e(),(item.car)?_c('span',[_vm._v(" ("+_vm._s(item.car)+" verfügbar) ")]):_c('span',[_vm._v("-")])]}},{key:"item.warmrent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.warmrent ? _vm.$n(item.warmrent, "currency") : "-")+" ")]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }